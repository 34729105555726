const initialState={
    count:0,
    card:0
}


const changeTheNumber = (state = initialState,action)=>{
    switch(action.type){
        case "NO_OF_GUESTS_INCR":
            return {count:state.count+ action.payload};
        case "NO_OF_GUESTS_DECR":
            return ({count:state.count <= 0?state.count = 0: state.count - action.payload})
        default: return state;
    }
}
export default changeTheNumber