const initialState={
    card:0
}


const AddtoCart = (state = initialState,action)=>{
    switch(action.type){
        case "NO_OF_GUESTS_DATA":
            return {card:state.card + 1}
        default: return state;
    }
}
export default AddtoCart