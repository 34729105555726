import React,{useState} from "react";
import { createContext } from "react";
import { useReducer } from "react"
import CategoryItems  from '../../CategoryItems.js'
import { Add_TO_CART,REMOVE_TO_CART,INCREMENT ,DECREMENT} from "../constant";
export let CartContext = createContext()
const reducer= (state,action)=>{
    switch(action.type){
        case Add_TO_CART:
            return{
                ...state,
            }
        case INCREMENT:
          return{...state,
            quantity:state.quantity + 1,
            data:action.payload}
        case DECREMENT:
          return{...state,
            quantity:state.quantity - 1}
        default:
            return state;
    }
}
let game = {
    CategoryItems
}
export const Cart=(props)=>{
    const [MainMenu,setMainMenu]=useState(game.CategoryItems)
    let initialState = {
        MainMenu,
        totalPrice:0,
        totalItems:0,
        quantity:0,
        data:[]
    }
  const [state,dispatch]=useReducer(reducer,initialState)

  let addTCard=(item)=>{
        return(
            dispatch({
                type:INCREMENT,
                payload:item
            })
        )
    }
    return(
    <CartContext.Provider value={{...initialState,addTCard,state,dispatch,setMainMenu}}>
    {props.children}
    </CartContext.Provider>
    )
}
