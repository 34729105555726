import './App.css';
import React from 'react';
import AboutUs from './screens/AboutUs';
import Home from './screens/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mateen</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <Router>
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
