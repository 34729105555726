import React, { useState,useEffect,useContext } from "react";
import { useDispatch,useSelector } from "react-redux/es/exports";
import { ADDITIONAL_INFORMATION } from "./Reducer/constant/index";
import { Link } from "react-scroll";
import {  toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import Burger from '../assets/Group4/Bottle.png'
import Bottle from '../assets/Group4/Burger.png'
import BurgerWithPlate from '../assets/Group4/Bottle1.png'
import { CartContext } from "./Reducer/Reducers/index";
const AdditionalForm = () => {
  const dispatcha = useDispatch();
  const myState = useSelector((state) => state.changeTheNumber);
  const addtoCrd = useSelector((state) => state.AddtoCart);
  const [store, setStore] = useState([]);
  const [noGuest,setnoGuest]=useState()
  const [Message, sendMessage] = useState("https://wa.me/+923363300444?text=");
  const [data, sesetData] = useState({
    name: "",
    cell: "",
    deliveryPlace: "",
    deliveryDate: "",
  });
  const handlerChange = (e) => {
    sesetData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const toastOption = {
    position:'top-center',
    autoClose:5000,
    pauseOnHover:true,
    draggable:true,
    theme:"light",
}
const CrdContext = useContext(CartContext);
  const { state, dispatch } = CrdContext;
  let gdata = state.data;
  useEffect(()=>{
    setnoGuest(myState.count)
  },[myState])
  let itemData = [];
  let priceData = [];
  store.map((items) => {
    itemData.push(items.title);
    priceData.push(items.price);
  });
  useEffect(() => {
    if (gdata == "") {
    } else {
      setStore([...store, gdata]);
     
    }
  }, [addtoCrd]);
  const handlerSubmit = (e) => {
    // e.preventDefault();
    if(store.length>0){
      const {name,cell,deliveryPlace,deliveryDate}= data

      let messageData = [
        "*Name* " +
          [name] +
          "%0A" +
          "*Cell* " +
          [cell] +
          "%0A" +
          "*deliveryPlace* " +
          [deliveryPlace] +
          "%0A" +
          "*Delivery Date* " +
          [deliveryDate] +
          "%0A" +
          "*Total Items* " +
          [itemData.length] +
          "%0A" +
          "*Guest* " +
          [noGuest] +
          "%0A",
      ];
      {
        store.map((items3) => {
          messageData.push("*" + [items3.title] + "* " + "%0A");
        });
      }
      sendMessage(Message + messageData);

      dispatcha({ type: ADDITIONAL_INFORMATION, data });
    }else{

      const {name,cell,deliveryPlace,deliveryDate}= data
      let messageData = [
        "*Name* " +
          [name] +
          "%0A" +
          "*Cell* " +
          [cell] +
          "%0A" +
          "*Delivery Place* " +
          [deliveryPlace] +
          "%0A" +
          "*Delivery Date* " +
          [deliveryDate] +
          "%0A" 
        
      ];
      sendMessage(Message + messageData);
    
      sesetData({
        name: "",
        cell: "",
        deliveryPlace: "",
        deliveryDate: "",
      });
    }
  };
  return (
    <div id="bookNow" className="w-full relative lg:mt-16">
      <img src={Burger} className="xs:-left-6 xs:bottom-10 xs:w-[150px] absolute w-[160px] sm:w-[200px] -bottom-12 left-12 md:left-7 sm:left-0" alt="" />
      <img src={Bottle} className=" absolute w-[100px]" alt="" />
      <img src={BurgerWithPlate} className=" absolute w-[100px] right-0" alt="" />
      <div className="w-[90%] mx-auto my-0">
        <h1 className="text-center lg:text-5xl text-3xl my-8 text-orange font-oleo font-bold md:text-4xl">
          Booking Form
        </h1>
        <form
          
          className="w-[70%] mx-auto my-0 py-4  flex flex-wrap"
        >
          <span className="w-full lg:w-[50%] md:w-[50%] px-4 py-1">
            <h4 className="md:text-xl font-[500]">NAME:</h4>
            <input
              type="text"
              name="name"
              value={data.name}
              onChange={handlerChange}
              className="border-orange border-2 py-2 px-2 rounded border-solid w-full"
            />
          </span>
          
          <span className="w-full lg:w-[50%] md:w-[50%] px-4 py-1">
            <h4 className="md:text-xl font-[500]">CELL NO:</h4>
            <input
              type="number"
              name="cell"
              value={data.cell}
              onChange={handlerChange}
              className="border-orange border-2 py-2 px-2 rounded border-solid w-full"
            />
          </span>
          
          <span className="w-full lg:w-[50%] md:w-[50%] px-4 py-1">
            <h4 className="md:text-xl font-[500]">DELIVERY PLACE:</h4>
            <input
              type="text"
              name="deliveryPlace"
              value={data.deliveryPlace}
              onChange={handlerChange}
              className="border-orange border-2 py-2 px-2 rounded border-solid w-full"
            />
          </span>
          <span className="w-full lg:w-[50%] md:w-[50%] px-4 py-1">
            <h4 className="md:text-xl font-[500]">DELIVERY DATE:</h4>
            <input
              type="date"
              name="deliveryDate"
              value={data.deliveryDate}
              onChange={handlerChange}
              className="border-orange border-2 py-2 px-2 rounded border-solid w-full"
            />
          </span>
          
         
          <span className="w-full lg:w-[50%] md:w-[50%] px-4 py-2">
              {/* <a
                type="submit"
                href={Message}
                onClick={handlerSubmit}
                className="text-center w-full font-[500] hover:bg-[#f17106] text-2xl bg-orange text-white rounded-md py-2 px-4"
              >
               Submit
              </a> */}
              {store>0?<a
                type="submit"
                href={Message}
                onClick={handlerSubmit}
                className="text-center w-full cursor-pointer font-[500] hover:bg-[#f17106] text-2xl bg-orange text-white rounded-md py-2 px-4"
              >
               Submit
              </a>:<a
                type="submit"
                href={Message}
                onClick={handlerSubmit}
                className="text-center w-full cursor-pointer font-[500] hover:bg-[#f17106] text-2xl bg-orange text-white rounded-md py-2 px-4"
              >
               Submit
              </a>}
          </span>
        </form>
      </div>
    </div>
  );
};

export default AdditionalForm;
