export const NO_OF_GUESTS_INCR = (num)=>{
    return{
        type:"NO_OF_GUESTS_INCR",
        payload:num

    }
}
export const NO_OF_GUESTS_DECR = (num)=>{
    return{
        type:"NO_OF_GUESTS_DECR",
        payload:num
    }
}
export const NO_OF_GUESTS_DATA = (num)=>{
    return{
        type:"NO_OF_GUESTS_DATA",
        payload:num
    }
}