import changeTheNumber from "./noOfGuest";
import AddtoCart from "./AddtoCrt";
import addInformation from "./addinformation";

import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    changeTheNumber,
    AddtoCart,
    addInformation
})

export default rootReducer;