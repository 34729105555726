let MainMenu=[
          {
            title: "Chicken Biryani",
            category:'chicken & chicken',
            subCategory:'rice'
          },
          {
            title: "Chicken Pulao",
            category:'chicken & chicken',
            subCategory:'rice'
          },
          {
            title: "Chicken Qorma",
            category:'chicken & chicken',
            subCategory:'gravy'
          },
          {
            title: "Chicken Karahi",
            category:'chicken & chicken',
            subCategory:'gravy'

            
          },

          {
            title: "Nan",
            category:'chicken & chicken',
            subCategory:'roti'
          },
          
          {
            title: "Taftan",
            category:'chicken & chicken',
            subCategory:'roti'
          },


          {
            title: "Salad",
            category:'chicken & chicken',
            subCategory:'salad'
          },

          {
            title: "Raita",
            category:'chicken & chicken',
            subCategory:'salad'
          },
          //BEEF ______________________
          {
            title: "Beef Biryani",
            category:'chicken & beef',
            subCategory:'rice'
          },

          {
            title: "Beef Pulao",
            category:'chicken & beef',
            subCategory:'rice'
          },
          
          
          {
            title: "Chicken Qorma",
            category:'chicken & beef',
            subCategory:'gravy'
          },

          {
            title: "Chicken Karahi",
            category:'chicken & beef',
            subCategory:'gravy'
          },


          {
            title: "Nan",
            category:'chicken & beef',
            subCategory:'roti'
          },
          
          {
            title: "Taftan",
            category:'chicken & beef',
            subCategory:'roti'
          },


          {
            title: "Salad",
            category:'chicken & beef',
            subCategory:'salad'
          },

          {
            title: "Raita",
            category:'chicken & beef',
            subCategory:'salad'
          },
         
         //MUTTON ______________________
          {
            title: "Mutton Qorma",
            category:'chicken & mutton',
            subCategory:'gravy'
          },
         

          {
            title: "Mutton Karahi",
            category:'chicken & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Kunna",
            category:'chicken & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Stew",
            category:'chicken & mutton',
            subCategory:'gravy'
          },


          {
            title: "Chicken Biryani",
            category:'chicken & mutton',
            subCategory:'rice'
          },
          

          {
            title: "Chicken Pualo",
            category:'chicken & mutton',
            subCategory:'rice'
          },


          

          {
            title: "Nan",
            category:'chicken & mutton',
            subCategory:'roti'
          },
          
          {
            title: "Taftan",
            category:'chicken & mutton',
            subCategory:'roti'
          },


          {
            title: "Salad",
            category:'chicken & mutton',
            subCategory:'salad'
          },

          {
            title: "Raita",
            category:'chicken & mutton',
            subCategory:'salad'
          },
          
          //MUTTON MUTTONN_________________
          {
            title: "Mutton Pualo",
            category:'mutton & mutton',
            subCategory:'rice'
          },

          {
            title: "Mutton Biryani",
            category:'mutton & mutton',
            subCategory:'rice'
          },


          {
            title: "Mutton Qorma",
            category:'mutton & mutton',
            subCategory:'gravy'
          },
         

          {
            title: "Mutton Karahi",
            category:'mutton & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Kunna",
            category:'mutton & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Stew",
            category:'mutton & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Stew",
            category:'mutton & mutton',
            subCategory:'gravy'
          },


          {
            title: "Mutton Stew",
            category:'mutton & mutton',
            subCategory:'gravy'
          },

          //_________MEHNDIMAYUN

         {
  title: "Qeema Green Chilli",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Puri (Live)",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Shahi Tarkari",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Kachoori",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Salad",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Kheer",
  category: "mehndi/mayun",
  subCategory: "option 1"
},
{
  title: "Sangam Kabab",
  category: "mehndi/mayun",
  subCategory: "option 2"
},
{
  title: "Shahi Tarkari",
  category: "mehndi/mayun",
  subCategory: "option 2"
},
{
  title: "Kachoori",
  category: "mehndi/mayun",
  subCategory: "option 2"
},
{
  title: "Onion-Chatni",
  category: "mehndi/mayun",
  subCategory: "option 2"
},
{
  title: "Suji Halwa",
  category: "mehndi/mayun",
  subCategory: "option 2"
},

          {
            title: "Puri (Live)",
            category:'mehndi/mayun',
            subCategory:'option 2'
          },
          {
            title: "Paratha (Live)",
            category:'mehndi/mayun',
            subCategory:'option 2'
          },


          {
            title: "Behari Kabab (Live)",
            category: "mehndi/mayun",
            subCategory: "option 3"
          },
          {
            title: "Shahi Tarkari",
            category: "mehndi/mayun",
            subCategory: "option 3"
          },
          {
            title: "Kachoori",
            category: "mehndi/mayun",
            subCategory: "option 3"
          },
          {
            title: "Onion-Chatni",
            category: "mehndi/mayun",
            subCategory: "option 3"
          },
          {
            title: "Rabri kheer",
            category: "mehndi/mayun",
            subCategory: "option 3"
          },

          {
            title: "Puri (Live)",
            category:'mehndi/mayun',
            subCategory:'option 3'
          },
          {
            title: "Parathe (Live)",
            category:'mehndi/mayun',
            subCategory:'option 3'
          },


          {
            title: "Shahi Tarkari - Salad-Chatni - Gulab Jaman",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },

          {
            title: "Salad-Chatni",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },
          {
            title: "Gulab Jaman",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },
          
          {
            title: "Chicken Pulao ",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },
          
          {
            title: "Chicken Biryani",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },

          {
            title: "Seekh Kabab",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },

          {
            title: "Gola Kabab",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },
          {
            title: "Puri",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },


          {
            title: "Nan",
            category:'mehndi/mayun',
            subCategory:'option 4'
          },


      
          



          {
            title: "Beef Boti B.B.Q ",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },

          {
            title: "Shahi Tarkari",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },

          {
            title: "Kachoori",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


          {
            title: "Dhai Phulki ",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


          {
            title: "Chatni-Salad ",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


          {
            title: "Kulfi Crunch falooda",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


       

          {
            title: "Mutton Pulao",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


          {
            title: "Mutton Biryani",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },

          {
            title: "Puri",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },


          {
            title: "Nan",
            category:'mehndi/mayun',
            subCategory:'option 5'
          },

          {
            title: "Singapuran Rice ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: " Chicken Karahi ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: " Mexican Chicken",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: "Turkish Kabab ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: "Puri Paratha ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: " Rubri Kheer ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: " Taftan",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: "Roti ",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },
          {
            title: " Salad",
            category:'mehndi/mayun',
            subCategory:'option 6'
          },



          {
            title: "Turkish Kabab ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
          
          {
            title: "Puri Paratha ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
          {
            title: "Kachoori ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
          {
            title: "Shahi Tarkari ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
          {
            title: "Suji Halwa ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
          {
            title: "Chatni Piaz",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
       
          
          {
            title: "Beef Pulao ",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },

          {
            title: "Beef Biryani",
            category:'mehndi/mayun',
            subCategory:'option 7'
          },
 


          {
            title: "Chandan Kabab",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },

          {
            title: "Puri Paratha ",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },


          {
            title: " Kachori Tarkari ",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },


          {
            title: " Gulab Jaman ",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },


          {
            title: "Dahi Phulki ",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },


          {
            title: "Chatni",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },

          {
            title: "Piyaz",
            category:'mehndi/mayun',
            subCategory:'option 8'
          },




          //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          {
            title: "VEAL BIRYANI - MUTTON KUNNA - CHICKEN BALOUCHI TIKKA - CHAPLI KABAB - GAJAR HALWA - GULAB JAMAN  - SALAD BAR - RAITA",
            category:'wedding/valima',
            subCategory:'Menu 1'
          },

          {
            title: "TANDOOR (LIVE) ",
            category:'wedding/valima',
            subCategory:'Menu 1'
          },

          {
            title: "TAFTAN ",
            category:'wedding/valima',
            subCategory:'Menu 1'
          },
//////////////////////////////////////////////////////

          {
            title: "VEAL YAKHNI PULAO ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },

          {
            title: "CHICKEN MUGHLAI KARAHI (LIVE) ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },

          {
            title: "CHICKEN CHATKHARA BOTI  (BONELESS) ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },




          {
            title: "CHANDAN KABAB ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },
          {
            title: " FRUIT COCKTAIL",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },
          {
            title: "ICE CREAM ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },
          {
            title: " TAFTAN ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },
          {
            title: " ROTI ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },

          {
            title: " SALAD BAR",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },


          {
            title: "RAITA ",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },


          {
            title: "CHICKY MICKY",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },


          {
            title: "KASHMIRI TEA",
            category:'wedding/valima',
            subCategory:'Menu 2'
          },

///////////////////////////////////////////////

{
  title: "CHICKEN BIRYANI",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "MUTTON STEW",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "CHICKEN PISHAWARI KARAHI (LIVE)",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "RAISHMI KABAB",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "GOLA KABAB",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "CHICKEN SHANWARI TIKKA",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "DOODH DULARI",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "QULFI QRANCH FALOODA",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "ARABIAN PUFF",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "TANDOOR LIVE",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 3"
},
{
  title: "WELCOME DRINK (MOCKTAIL)",
  category: "wedding/valima",
  subCategory: "Menu 3"
},



{
  title: "CHICKEN YAKHI PULAO",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "CHICKEN KARAHI GREEN",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "CHICKEN ALAKIVE",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "SANGAM KABAB",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "LAB E SHIREEN",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "ICE CREAM",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "WONTON SAMOSA",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "MILKY NAN",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
{
  title: "WELCOME DRINK (MOCKTAIL)",
  category: "wedding/valima",
  subCategory: "Menu 4"
},
/////////////////////////////////////////////////////////////////////

{
  title: "MUTTON YAKHNI PULAO",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "CHICKEN SHANWARI KARAHI (LIVE)",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "CHICKEN CRISP",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "CHAPLI KABAB",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "ALFREDO PASTA",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "DESSERT BAR",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "QULFI QRANCH FALOODA",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "TANDOOR LIVE",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 5"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 5"
},

///////////////////////////////

{
  title: "CHICKEN BIRYANI",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "CHICKEN PISHAWARI KARAHI (LIVE)",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "CHICKEN BIHARI TIKKA",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "CHERY CREAM",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "ROTI",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "MINT ROLL",
  category: "wedding/valima",
  subCategory: "Menu 6"
},
{
  title: "ICE CREAM",
  category: "wedding/valima",
  subCategory: "Menu 6"
},

//////////////////////////////






{
  title: "VEAL BIRYANI",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "CHICKEN SHAHI KARAHI",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "MUTTON KUNNA",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "MEXICAN CHICKEN",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "CHICKEN CORDON BLUE",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "MUTTON TANDOORI (FULL)",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "CHERRY QRANCH DELIGHT",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "LIVE ICE CREAM (TAWA)",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "TANDOOR LIVE",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 8"
},
{
  title: "WELCOME DRINK (FRESH)",
  category: "wedding/valima",
  subCategory: "Menu 8"
},


//////////////////////////////

{
  title: "MUTTON BIRYANI",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "CHICKEN HANDI",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "TURKISH KABAB (CHICKEN)",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "FISH BISCUITS",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "RUBRI KHEER",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "LIVE ICE CREAM (TAWA)",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "TAFTAN",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "TANDOOR (LIVE)",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "SALAD BAR",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "RAITA",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "PERRY BITE",
  category: "wedding/valima",
  subCategory: "Menu 7"
},
{
  title: "ESPRESSO COFFEE",
  category: "wedding/valima",
  subCategory: "Menu 7"
}

        ]
export default MainMenu