import React from "react";
import "../App.css";
import Navbar from "./Navbar";
import Buryani from "../assets/img/Buryani.png";
const Intro = () => {
  return (
    <div className="headerimg lg:relative pb-4" id="Home">
      <div className="">
        <Navbar />
        <div className="">
          <div className="lg:flex lg:pt-40 px-8 lg:items-center">
            <div className="hd lg:text-start  md:flex md:items-center sm:flex sm:items-center text-white flex flex-col text-center lg:mt-0  mt-16 py-2 px-5 lg:pr-32">
              <h1 className="md:text-5xl text-4xl font-bold lg:text-7xl">Enjoy Our Delicious Biryani</h1>
              <p className="lg:text-xl mt-4 md:text-2xl ">
                In biryani, meat (and vegetables, if present) and rice are
                cooked separately before being layered and cooked together for
                the gravy to absorb into the rice.
              </p>
              <button className="md:text-2xl  sm:w-40 md:w-64 md:py-4 md:px-10  py-2 px4 mt-4 bg-orange font-bold uppercase lg:w-36 rounded" ><a href="https://wa.me/+923363300444">Book a table</a></button>
            </div>
            <div className="mt-4 overflow-hidden md:flex md:justify-center sm:flex sm:justify-center xs:flex xs:justify-center w-hidden">
              <img src={Buryani} className='lg:w-[90rem] sm:w-[800px] md:w-[800px] animate-spin-slow'  alt="" />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Intro;
