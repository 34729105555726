import React from 'react'
import CORPORATION from '../assets/img/corporations/(1).png'
import CORPORATION2 from '../assets/img/corporations/(2).png'
import CORPORATION3 from '../assets/img/corporations/(3).png'
import CORPORATION4 from '../assets/img/corporations/(4).png'
import CORPORATION5 from '../assets/img/corporations/(5).png'
import CORPORATION6 from '../assets/img/corporations/(6).png'
import CORPORATION7 from '../assets/img/corporations/(7).png'
import CORPORATION8 from '../assets/img/corporations/(8).png'
import CORPORATION9 from '../assets/img/corporations/(9).png'
import CORPORATION10 from '../assets/img/corporations/(10).png'
import CORPORATION11 from '../assets/img/corporations/(11).png'
import CORPORATION12 from '../assets/img/corporations/(12).png'
import CORPORATION13 from '../assets/img/corporations/(13).png'
import CORPORATION14 from '../assets/img/corporations/(14).png'
import CORPORATION15 from '../assets/img/corporations/(15).png'
const Corporation = () => {
  return (
    <div className="w-[90%] mx-auto my-0">
        <div className="text-5xl font-extrabold ">

        <h1 className="text-center lg:text-5xl text-3xl my-8 text-orange font-oleo font-bold md:text-4xl">
          Our Corporations
        </h1>
        </div>
    <div className='flex flex-wrap gap-2 justify-center items-center'>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer ' src={CORPORATION} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION2} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION3} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION4} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION5} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-24 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION6} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-24 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION7} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-28 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION8} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-36 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION9} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-24 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION10} alt="" />
        </div>
     
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-28 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION12} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-32 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION13} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-28 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION14} alt="" />
        </div>
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-24 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION15} alt="" />
        </div>
   
        <div className=" rounded-md h-[130px] flex justify-center items-center px-1">
            <img className='w-24 grayscale hover:grayscale-0 cursor-pointer' src={CORPORATION11} alt="" />
        </div>
     
    </div>
    </div>
  )
}

export default Corporation