import React,{useState} from "react";
import {
  faAngleRight,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {contactRoute} from '../utils/apiRoutes'
const Footer = () => {
  const [name,setName]= useState('')
  const [phone,setPhone]= useState('')
   
  const handlerSubmit=async(e)=>{
    e.preventDefault()
    let item = {
      name,
      phone
    }
    const {data} = await axios.post(contactRoute,item)
    setName("")
    setPhone("")
  }
  return (
    <div className="bg-dark text-white lg:mt-40" id="Contact">
      <div className="grid ">
        <div className="md:justify-between md:flex lg:flex lg:justify-evenly lg:gap-12 lg:mb-10 md:flex-wrap row-span-2 pt-14 px-6 md:px-12 md:pb-10">
        <div className="mx-auto my-0 max-w-[20rem] h-[9rem] md:mx-0 md:mt-[2rem] lg:mt-8 col-span-1 md:flex md:w-[300px] lg:w-[350px] md:flex-col font-semibold">
              <div className="h-[50px] font-mono space-y-2 font-semibold">
               <form className="lg:mt-2  lg:space-y-2" onSubmit={handlerSubmit}>
               <input value={name} onChange={(e)=>setName(e.target.value)} name="name" placeholder="Your Name" className="w-full border-2 border-orange outline-none  bottom-0 text-dark  p-3"  type='text'/>
                <input value={phone} onChange={(e)=>setPhone(e.target.value)} name="phone" placeholder="Your Phone Number" className="w-full border-2 border-orange outline-none  bottom-0 text-dark  p-3"  type='number'/>
                <button type="submit" className="w-full bottom-[6px] bor right-2 px-2 py-2 text-white bg-orange hover:bg-[#f17106]" >SUBMIT</button>
               </form>
              </div>
        </div> 

        <div className="col-span-1 md:flex md:w-[300px] md:text-xl md:flex-col font-semibold">
          <h1 className="text-orange  mb-2 mt-8 text-2xl font-oleo lg:text-3xl">Company</h1>
          <ul className="font-mono space-y-2 ">
            <li className="lg:text-xl"><FontAwesomeIcon icon={faAngleRight} className='mr-2' />Reservation</li>
            <li className="lg:text-xl"><FontAwesomeIcon icon={faAngleRight} className='mr-2' />Privacy Policy</li>
            <li className="lg:text-xl"><FontAwesomeIcon icon={faAngleRight} className='mr-2' />Terms & Condition</li>
          </ul>
        </div>
        <div className="col-span-1 md:flex md:w-[300px] md:text-xl md:flex-col font-semibold">
          <h1 className="text-orange  mb-2 mt-8 text-2xl font-oleo lg:text-3xl">Contact</h1>
          <ul className="font-mono space-y-2 ">
            <li className="lg:text-xl"><FontAwesomeIcon icon={faLocationDot} className='mr-2 lg:text-2xl'/>Block M, North Nazimabad Town,<br/> Karachi, Karachi City, Sindh</li>
            <li className="lg:text-xl"><FontAwesomeIcon icon={faPhone} className='mr-2 lg:text-2xl'/>+92-336-3300444</li>
            
          </ul>
        </div>
        <div className="mb-4 col-span-1 md:flex md:w-[300px] md:text-xl md:flex-col font-semibold">
          <h1 className="text-orange  mb-2 mt-8 text-2xl font-oleo lg:text-3xl">Opening</h1>
          <ul className="font-mono space-y-2 ">
            <li className="text-xl lg:text-2xl font-semibold">Monday - Sunday</li>
            <li className="lg:text-xl">12AM-12PM</li>
          </ul>
        </div>
        
        </div>
      </div>
      <div className="">
        <hr />
        <div className="px-4 text-center py-4">
          <h1 className="text-sm lg:text-2xl">@Choudhry Mateen Pakwan, All Right Reserved. <br />
          Designed By Zeap360</h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
