import React from 'react'
import Intro from '../Components/Intro';
import Menu from '../Components/Menu';
import ScrollToTop from '../Components/ScrollToTop';
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import AddToCart from '../Components/AddToCart';
import AdditionalForm from '../Components/AdditionalForm';
import Corporation from '../Components/Corporation';
const Home = () => {
  return (
    <div>
         <Intro/>
    <Services/>
    <Menu/>
    <AdditionalForm/>
    <Corporation/>
    <Footer/>
    <AddToCart/>
    <ScrollToTop/>
    </div>
  )
}

export default Home