import React,{useState,useEffect} from 'react'
import { useContext } from "react";
import {CartContext} from './Reducer/Reducers/index'
import CategoryItems  from './CategoryItems'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "../styles.css";

const Categories = () => {

  const [btn,setBtn] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(0);
  const CrdContext = useContext(CartContext)
  const {setMainMenu} = CrdContext
  const [btn1,setBtn1] = useState(false)
  const [selectedCategory1, setSelectedCategory1] = useState(0);
const [value,setValue]=useState([])
  let [count,setCount]=useState(0)
let data =["CHICKEN & CHICKEN","CHICKEN & BEEF","CHICKEN & MUTTON","MUTTON & MUTTON","DESSERTS", "MEHNDI/MAYUN" ,"WEDDING/VALIMA" ]
let category = ["chicken & chicken", "chicken & beef", "chicken & mutton", "mutton & mutton", "desserts","mehndi/mayun" , "wedding/valima"];


  
  let data1 =[
      ["rice","gravy" , "roti" , "salad"],
      ["rice","gravy" , "roti" , "salad"],
      ["rice","gravy" , "roti" , "salad"],
      ["rice","gravy" , "roti" , "salad"],
      ["Choice of DESSERT"],
      ["option 1", "option 2","option 3","option 4","option 5","option 6","option 7","option 8"],
      ["Menu 1", "Menu 2","Menu 3","Menu 4","Menu 5","Menu 6","Menu 7" , "Menu 8"]
    
      
     
              ]
  const ClickHandler=(index)=>{
    setCount(index)
    setSelectedCategory(index)
    let dish = category[index]
    let FianlData = CategoryItems.filter((e,ida)=>(
      e.category == dish
    ))
    setValue(FianlData)
    setBtn(!btn)
   
  }  
   const ClickHandler1=(index)=>{
      setSelectedCategory1(index)
      let dishas = data1[count][index]
      let FianlData1 =  value.filter((e,ida)=>(
        e.subCategory == dishas
      ))
      setMainMenu(FianlData1)
      setBtn1(!btn1)
      
    
     }
  useEffect(()=>{
   ClickHandler(0)
 
  },[])
 useEffect(()=>{
  ClickHandler1(0)
 },[value])



  return ( 
    <div className="md:mb-[1px] w-full bg-[#ebebeb]">
    <div className="flex flex-col items-center justify-content-center flex-wrap" >
   <div className="">
   <Swiper watchSlidesProgress={true} slidesPerView={3} className="mySwiper flex flex-wrap justify-center">
   {data.map((item,index)=>(
        <SwiperSlide key={index}
        onClick={()=>ClickHandler(index)}
        className={`${selectedCategory === index?'lg:bg-orange md:bg-orange bg-orange ':'lg:bg-white md:bg-white bg-white'} lg:flex  lg:items-center md:flex md:justify-center lg:justify-center md:items-center  flex justify-center rounded md:rounded lg:rounded items-center cursor-pointer  lg:min-w-[3rem] lg:h-[4rem] h-[3rem] md:h-[4rem] md:min-w-[4rem] w-[90px] md:w-auto lg:w-auto   min-w-[15%] text-center p-2 `}>
       
          <h1
          className={`${selectedCategory === index?'lg:text-white text-white md:text-white ':'lg:text-orange md:text-orange text-orange '}font-bold  lg:text-lg lg:font-bold md:text-lg md:font-bold border-b-2 pb-2 text-[12px] uppercase`}
          >{item}</h1>

        </SwiperSlide>
      ))}
      </Swiper>
   </div>
   
   <div className="">
   <Swiper watchSlidesProgress={true} slidesPerView={3} className="mySwiper flex gap-2 my-2 flex-wrap justify-center">

   {data1[count].map((item,index)=>(
        <SwiperSlide
        key={index}
        onClick={()=>ClickHandler1(index)}
        className={`${selectedCategory1 === index?'lg:bg-orange md:bg-orange bg-orange ':'lg:bg-white md:bg-white bg-white'} lg:flex  lg:items-center md:flex md:justify-center lg:justify-center md:items-center flex justify-center rounded-xl md:rounded-xl items-center cursor-pointer  lg:min-w-[5rem] lg:h-[3rem] h-[3rem] md:h-[3rem] md:min-w-[5rem] lg:rounded-xl min-w-[15%] text-center p-2 `}>
          <h1
          className={`${selectedCategory1 === index?'lg:text-white text-white md:text-white ':'lg:text-orange md:text-orange text-orange '}font-bold  lg:text-lg lg:font-bold md:text-xl md:font-bold border-b-2 pb-2 text-[12px] uppercase`}
          >{item}</h1>
        </SwiperSlide>
      ))}
      </Swiper>

   </div>
    </div>
    </div>   
    

  )
}

export default Categories