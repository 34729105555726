import React from 'react'
import '../App.css'
import { useScrollTo } from 'react-use-window-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
const ScrollToTop = () => {
  const scrollTo = useScrollTo();
  return (
    <div  className='scrl border-2 border-white rounded-full cursor-pointer z-50 text-center bg-orange text-white fixed bottom-4 right-4 md:h-[60px] md:w-[60px] lg:h-[70px] lg:w-[70px] h-[40px] w-[40px]'>
        <FontAwesomeIcon className='md:text-4xl lg:text-5xl text-xl scrl mt-[10px]' icon={faArrowUp}  onClick={() => scrollTo({ top: 0, left: 0, behavior: "smooth" })}/>
    </div>
  )
}

export default ScrollToTop