import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import { useContext } from "react";
import { CartContext } from "./Reducer/Reducers/index";
import { DECREMENT, INCREMENT } from "./Reducer/constant/index";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { NO_OF_GUESTS_INCR, NO_OF_GUESTS_DECR } from "./Reducer/action/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { Link } from "react-scroll";
import { dataRoute, contactRoute } from '../utils/apiRoutes'

const AddToCart = () => {
  const toastOption = {
    position: 'top-center',
    autoClose: 5000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  }

  const myState = useSelector((state) => state.changeTheNumber);
  const addtoCrd = useSelector((state) => state.AddtoCart);
  const addInfo = useSelector((state) => state.addInformation);
  const [noGuest, setnoGuest] = useState()

  const dispatcha = useDispatch();
  let a =
    "scrollbar-hide lg:rounded-lg  overflow-scroll lg:h-[400px] lg:w-[300px] md:w-[300px] w-[200px] my-2 px-2 py-4 z-50 bg-dark fixed md:right-[5rem] lg:right-[5rem] right-[3rem] bottom-[11%] rounded md:h-[400px] h-[300px] ";
  const [crt, setCrt] = useState(a);
  const [evt, setEvt] = useState(false);
  const [Message, sendMessage] = useState("https://wa.me/+923363300444?text=");
  const [btn, setBtn] = useState(
    "hidden md:text-xl md:h-10 md:py-1 cursor-pointer text-white text-center text-lg  mt-2 block bg-orange w-full rounded-md h-7"
  );
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState([]);

  const handlerClk = () => {
    setEvt(!evt);
    {
      evt ? setCrt(a + " hidden ") : setCrt(a + " block ");
    }
  };

  useEffect(() => {
    setnoGuest(myState.count)
  }, [myState])

  useEffect(() => {
    setCrt(a + " hidden ");
  }, []);

  const CrdContext = useContext(CartContext);
  const { state, dispatch } = CrdContext;
  let gdata = state.data;
  let q = state.quantity;
  useEffect(() => {
    if (gdata === "") {
    } else {
      setStore([...store, gdata]);
      setBtn(
        "text-white cursor-pointer relative md:py-1 md:h-10 text-center md:text-xl text-lg  mt-2 block bg-orange w-full rounded-md h-7"
      );
    }
  }, [addtoCrd]);

  let itemData = [];
  let priceData = [];
  store.map((items) => {
    itemData.push(items.title);
    priceData.push(items.price);
  });

  const {
    name,
    phone,
    cell,
    deliveryDate,
    deliveryPrice,
    address,
    totalAmount,
  } = addInfo.data;

  const newDat = {
    name,
    cell,
    deliveryDate,
    deliveryPrice,
    totalItems: itemData.length,
    guest: myState.count
  }

  const handlerDelete = (id1) => {
    let adf23 = store.filter((e, ida) => ida !== id1);
    dispatch({
      type: DECREMENT,
    });
    setStore(adf23);
    if (store.length === 1) {
      setBtn(btn + " hidden ");
    }
  };

  return (
    <div className="relative">
      <div
        onClick={handlerClk}
        className="cursor-pointer  fixed z-50  right-2 bottom-[40%] addtocrt"
      >
        <div className="relative">
          <div className="text-sm w-[20px] lg:text-xl lg:w-[30px] lg:h-[30px] h-[20px] md:w-[30px] md:h-[30px] rounded-full  border-blue bg-gray text-center absolute right-[0px] -top-4 flex justify-center items-center font-bold text-white   ">
            {state.quantity}
          </div>
          <FontAwesomeIcon
            className={
              state.quantity
                ? "adt"
                : " text-red lg:text-6xl text-4xl md:text-6xl"
            }
            icon={faCartPlus}
          />
        </div>
      </div>

      <div className="relative">
        <div className={crt}>
          <h1 className="  text-white text-center text-lg bg-orange  rounded-md  h-7">
            No of Guests
          </h1>
          <div className="flex justify-center items-center gap-1 mt-2">
            <a
              onClick={() => dispatcha(NO_OF_GUESTS_INCR(50))}
              className="w-7 h-7 bg-orange text-white cursor-pointer text-2xl rounded-md leading-none text-center"
            >
              +
            </a>
            <input
              type="number"
              className="w-full rounded-md h-7 text-center text-black"
              onChange={(e) => setnoGuest(e.target.value)}
              value={noGuest}
              required
              min={0}
              max={1000}
            />
            <a
              onClick={() => dispatcha(NO_OF_GUESTS_DECR(50))}
              className="w-7 h-7 bg-orange text-white cursor-pointer text-2xl rounded-md leading-none text-center"
            >
              -
            </a>
          </div>
          <div className="space-x-2 px-4 flex text-center items-center justify-evenly text-white bg-orange mt-2 rounded-md text-base lg:text-lg">
            <span>Total Items</span>
            <span>{itemData.length}</span>
          </div>
          <div className="">
            {store.map((items2, index) => (
              <div key={index} className="">
                <div className="flex justify-between my-2   py-1 px-1   text-white">
                  <h1 className="text-base lg:text-xl w-[30%]">
                    {items2.title}
                  </h1>
                  <button
                    className="bg-red px-2 rounded-md"
                    onClick={() => handlerDelete(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <Link spy={true} to="bookNow" smooth={true} className={btn} ><p  >
              Next
            </p></Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddToCart;
