import React, { useState, useEffect } from "react";
import '../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Logo from '../assets/img/logo.png';
import { Link } from "react-scroll";
import { Link as Links } from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [cls, setCls] = useState('z-50 lg:fixed lg:w-full lg:top-0  lg:flex lg:flex-row lg:items-center  flex flex-col justify-between py-2 px-2 ');
  const [menu, setMenu] = useState('lg:hidden');

  useEffect(() => {
    const timeoutId = setTimeout(() => setMenu('lg:hidden'), 2000);

    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures this runs only once on mount

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 120) {
      setCls('lg:bg-dark nv z-50 lg:fixed lg:w-full lg:top-0  lg:flex lg:flex-row lg:items-center  flex flex-col justify-between py-2 px-2 ');
    } else {
      setCls('z-50 lg:fixed lg:w-full lg:top-0  lg:flex lg:flex-row lg:items-center  flex flex-col justify-between py-2 px-2 ');
    }
  });

  return (
    <div id="navdark" className={cls}>
      <div className="flex items-center py-2 justify-between lg:border-none  border-b-2 border-gray">
        <div className="w-[30rem]">
          <img src={Logo} className='w-[80%]' alt="" />
        </div>
        <div id="menuicon" className={menu} onClick={() => { setNav(!nav); setMenu('border-4 rounded py-1 pl-2 border-gray transition'); }}>
          {!nav ?
            <FontAwesomeIcon icon={faBars} className="sm:text-4xl md:text-4xl  text-2xl mr-2 text-gray " /> :
            <FontAwesomeIcon icon={faXmark} className=" border-gray sm:text-4xl md:text-4xl text-2xl mr-2 text-gray" />}
        </div>
      </div>
      <div className="lg:hidden">
        <nav className="">
          <ul className={!nav ? 'opacity-0 absolute top-0' : 'ml-2 mt-4 text-white transition-all ease-in opacity-100 duration-500'}>
            {/* Your navigation links go here */}
          </ul>
        </nav>
      </div>
      <div>
        <nav className="">
          <ul className="hidden mb-3 lg:items-center lg:flex lg:text-white">
            {/* Your navigation links go here */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
