import React from 'react';
import Logo from '../assets/img/logo.png';
import Navbar from '../Components/Navbar';

const AboutUs = () => {
  return (
    <div className='flex items-center h-[100vh] justify-center bg-orange'>
      <Navbar />
      <div className="text-center xs:h-[80%] h-[60%] w-[70%]">
        <div className="bg-white rounded-md mb-4">
          <img src={Logo} alt="" />
        </div>
        <h1 className='text-white font-bold text-lg py-2 md:text-base sm:text-sm xs:text-sm'>INTERNATIONAL FOOD SINCE 1980</h1>
        <p className='text-lg md:text-base sm:text-sm xs:text-sm'><strong className='text-white'>"CHOUDHRY MATEEN" </strong>
          has been in the catering business for more than 37 years. We started with</p>
        <p className='text-lg md:text-base sm:text-sm xs:text-sm'><strong className='text-white'>"Pakwan Center"</strong>
          in 1980 & grown ourselves to be one of the leading food taste providers, <strong className='text-white'>"CHOUDHRY MATEEN</strong> International Food"</p>
        <p className='text-lg md:text-base sm:text-sm xs:text-sm'>In addition, we also have successfully running restaurants in Karachi & Toronto, Canada for the past 37+ years where we provide all kinds of Pakistani & Indian Cuisine to our Asian & North American community. We are known for our <strong className='text-white'>Quality And Quantity</strong></p>
        <p className='text-lg md:text-base sm:text-sm xs:text-sm'>which makes us one of the best & well-known caterers in international food.</p>
        <h3 className='xs:mt-2 text-lg md:text-base sm:text-sm xs:text-sm text-white font-bold'>HOT LINE: 0336-3300444</h3>
      </div>
    </div>
  );
};

export default AboutUs;
