import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUtensils,
  faCartShopping,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  let data = [
    {
      icon: faUser,
      title: "Master Cheafs",
      text: "A chief cook (often shortened to cook) is a seniormost unlicensed crewmember working in the stewards department of a merchant ship.",
    },
    {
      icon: faUtensils,
      title: "Quality Food",
      text: " the term food quality represents the sum of all properties and attributes of a food item that are acceptable to the customer.",
    },
    {
      icon: faCartShopping,
      title: "Order Online",
      text: "Fast and easy food delivery from the best restaurants near you ✓ Large variety of cuisines, diverse dishes ✓ Safe & easy payment options.",
    },
    {
      icon: faPhone,
      title: "24/7 Service",
      text: "We provide 24/7 food, which is freshly prepared on your orders with customisation!",
    },
  ];
  return (
    <div id="Service">
      <div  className=" mt-16 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
      {data.map((items,index) => (
        <div  key={index}
          className="sr   w-[90%] mx-auto  my-4 shadow-[0px_1px_29px_0px_rgba(64,56,56,0.39);]  py-10 px-5"
        >
          <FontAwesomeIcon
            icon={items.icon}
            className="text-5xl text-orange "
          />
          <h1 className="font-bold pt-4 pb-2 text-xl">{items.title}</h1>
          <p>{items.text}</p>
        </div>
      ))}
    </div>
    </div>
  );
};

export default Services;
