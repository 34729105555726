import React, { useCallback } from "react";
import { useContext } from "react";
import { CartContext } from "./Reducer/Reducers/index";
import { useDispatch } from "react-redux/es/exports";
import { NO_OF_GUESTS_DATA } from "./Reducer/action/action";
import { useState } from "react";
const CategoriesItems = () => {
  const [selected,setSelected] = useState([])
  const dispatcha = useDispatch();
  const CrdContext = useContext(CartContext);
  const { MainMenu, addTCard } = CrdContext;
   const handlerItemClick = (index)=>{
    setSelected([...selected,index])
   }
  
  return (
    <div className="min-w-[100%] bg-[#ebebeb]">
      <div className="mySwiper  lg:mt-[1rem]">
      <div  className="lg:pb-10 py-2 xs:gap-2 sm:gap-2 lg:gap-y-4 lg:justify-center  justify-center flex-wrap md:mt-4 flex lg:flex-wrap lg:gap-[0.5rem] md:gap-[0.5rem]   lg:w-full w-full md:flex md:justify-center  md:w-full">
      {MainMenu.map((items, index) => (
            <div className="xs:w-[48%] " key={index}>
              <div className=" md:gap-y-3 lg:gap-[0.5rem] justify-between  md:gap-[0.5rem]  md:justify-between lg:justify-between lg:relative relative md:relative md:flex  lg:p-0   md:w-full w-full  text-center  bg-[#ebebeb] flex   items-center">
                {/* <div className="lg:h-[70%] h-[50%] ">
                  <img
                    src={items.img}
                    className="w-[90px] h-[90px] md:w-[65px] md:h-[65px]  lg:w-[65px] lg:h-[65px]  rounded-full border-[3px] border-white"
                    alt=""
                  />
                </div> */}
                <h1 
                 onClick={() => (
                  addTCard(items), dispatcha(NO_OF_GUESTS_DATA(),handlerItemClick(items.title))
                )}
                
                className={`${selected.find((da)=>da === items.title)  ?' md:border-green border-green text-green md:text-green lg:border-green':'lg:bg-white md:bg-white bg-white md:border-orange border-orange lg:border-orange'}  text-orange  xs:w-[100%] xs:text-sm md:border-2 border-2 md:cursor-pointer cursor-pointer  md:p-2 p-2 md:rounded-md rounded-md  lg:border-2 lg:cursor-pointer  lg:p-2 lg:rounded-md  lg:text-[15px]  md:text-[15px]  font-bold text-lg`}>
                  {items.title}
                </h1>
                {/* <button
                  onClick={() => (
                    addTCard(items), dispatcha(NO_OF_GUESTS_DATA())
                  )}
                  className="mr-[3px] lg:text-[15px] mt-2 py-2 px-2  md:py-0 md:px-2 md:text-xl font-bold text-white md:font-bold md:text-white rounded bg-orange"
                >
                 Add to Cart
                </button> */}

              </div>
            </div>
          ))}
      </div>
      </div>
     
   
    </div>
  );
};

export default CategoriesItems;
