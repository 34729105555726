import React from "react";
import CategoriesItems from "./CategoriesItems";
import Categories from "./Categories";
import MainMenu from "./CategoryItems";
const Menu = () => {
  return (
    <div className="mb-8" id="Menu">
      <div className="mt-8 text-center p-4">
        <h1 className="font-oleo text-orange text-5xl font-bold">Our Menu</h1>
        <p className="mt-4">
          There isn't anything on their menu that you wouldn't enjoy. Absolutely
          delicious and authentic with traditional style of food. Their service
          is fast and ...
        </p>
      </div>
      <div className="">
        <div id="arrowAnim">
          <h1 className="text-xl py-2 px-2 border-2 border-orange rounded-md font-oleo font-bold text-orange">Scroll Menu</h1>
          <div className="arrowSliding">
            <div className="arrow"></div>
          </div>
          <div className="arrowSliding delay1">
            <div className="arrow"></div>
          </div>
          <div className="arrowSliding delay2">
            <div className="arrow"></div>
          </div>
          <div className="arrowSliding delay3">
            <div className="arrow"></div>
          </div>
        </div>
      </div>
      <div className="w-[90%] mx-auto my-4 shadow-2xl">
        <div className="flex border-b-[1px] border-orange hover:border-white  overflow-x-auto scrollbar-hide">
          <Categories />
        </div>
        <div className="flex overflow-x-auto">
          <CategoriesItems />
        </div>
      </div>
    </div>
  );
};

export default Menu;
