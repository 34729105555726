import {ADDITIONAL_INFORMATION} from '../constant/index'

const initialState={
    data:''
}
const addInformation = (state = initialState ,action)=>{
    switch(action.type){
        case ADDITIONAL_INFORMATION:
            return {...state,
                data:action.data}
        default:
            return state
    }
}
export default addInformation;